import React, { Component } from "react";
import RichTextPage from "./RichTextPage";

class Terms extends Component {
  render() {
    return <RichTextPage pageNumber={2} />
  }
}

export default Terms;
