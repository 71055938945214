import React, { Component } from "react";
import RichTextPage from "./RichTextPage";

class DataSecurity extends Component {
  render() {
    return <RichTextPage pageNumber={3} />
  }
}

export default DataSecurity;

