import React, { Component } from "react";
import RichTextPage from "./RichTextPage";

class Imprint extends Component {
  render() {
    return <RichTextPage pageNumber={1} />
  }
}

export default Imprint;
